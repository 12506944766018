@import "../scss/variables";


/*--- Default-header ---*/

.default-header {
	.header-search {
		background: none;
		position: inherit;
	}
	.header-white {
		display: none;
	}
	.header-search .header-icons .header-icons-link li a {
		background: $black-2;
		border: 0;
	}
	.top-bar {
		border-bottom: 1px solid $black-2;
		background: $black-1;
	}
	.top-bar-right span,
	.top-bar-left span {
		color: $white;
	}
	.top-bar .contact i {
		color: $white !important;
		&.text-white {
			color: $white !important;
		}
	}
	.top-bar-left .select2-container--default .select2-selection--single .select2-selection__arrow b {
		border-color: $white transparent transparent transparent;
	}
}


/*--- Header-Style1 ---*/

.headerstyle1 {
	.header-search {
		background: $white;
		position: relative;
	}
	.header-brand-img {
		display: none;
	}
	.header-white {
		display: block;
	}
	.header-search .header-icons .header-icons-link li a {
		background: transparent;
		border: 1px solid #d3d9e0;
	}
	.top-bar {
		background: $white;
		position: relative;
		border-bottom: 1px solid #d3d9e0;
	}
	.top-bar-right span,
	.top-bar-left span {
		color: #2e384d;
	}
	.top-bar .contact i {
		color: #2e384d !important;
		.text-white.ml-1 {
			color: #2e384d !important;
		}
	}
	.top-bar-left .select2-container--default .select2-selection--single .select2-selection__arrow b {
		border-color: #2e384d transparent transparent transparent;
	}
	.header-main i {
		color: #3e4f65 !important;
	}
	.header-icons-link .header-icons-link1 i {
		color: #7c90a0;
	}
	.horizontal-main.header-style1.bg-dark-transparent .header-white {
		display: none;
	}
}


/*--- Header-Style2 ---*/

.headerstyle2 {
	.header-search {
		background: $white;
		position: relative;
	}
	.header-brand-img {
		display: none;
	}
	.header-white {
		display: block;
	}
	.header-search .header-icons .header-icons-link li a {
		background: transparent;
		border: 1px solid #d3d9e0;
	}
	.top-bar {
		background: $black;
		position: relative;
		border-bottom: 1px solid #d3d9e0;
	}
	.top-bar-right span,
	.top-bar-left span {
		color: $white;
	}
	.top-bar .contact i {
		color: $white !important;
		.text-white.ml-1 {
			color: $white !important;
		}
	}
	.top-bar-left .select2-container--default .select2-selection--single .select2-selection__arrow b {
		border-color: $white transparent transparent transparent;
	}
	.header-main i {
		color: $white;
	}
	.header-icons-link .header-icons-link1 i {
		color: #7c90a0;
	}
	.horizontal-main.header-style1.bg-dark-transparent .header-white {
		display: none;
	}
	.header-search-logo.header-style-2 .header-brand-img {
		display: block;
		float: none;
		margin: 0 auto;
	}
}

.headerstyle1 .header-search-logo.header-style-2 .header-white {
	display: block;
	float: none;
	margin: 0 auto;
}

.headerstyle2 {
	.header-search-logo.header-style-2 .header-brand-img.header-white {
		display: none;
	}
	.top-bar-right .header-search-logo .header-brand-img {
		&.header-white {
			display: none;
		}
		display: block;
	}
}


/*--- Defalut-Horizontal-Menu ---*/

@media (min-width: 992px) {
	.default-menu .horizontal-main {
		background: $secondary;
	}
}


/*--- Horizontal-menu Style1 ---*/

@media (min-width: 992px) {
	.menu-style1 .horizontal-main {
		background: $black-3 !important;
	}
}


/*--- Horizontal-menu Style2 ---*/

@media (min-width: 992px) {
	.menu-style2 {
		.horizontal-main {
			background: none !important;
		}
		.horizontalMenu {
			background: $secondary;
			padding: 0 10px;
			border-radius: 3px;
		}
		.horizontal-main.header-style1.bg-dark-transparent .horizontal-mainwrapper {
			background: #200454;
		}
	}
}